(function(){
	'use strict';

	angular.module('aerosApp')
		.directive('resultEfi', [function () {
			return {
				restrict: 'A',
				scope: {
					fiberGroup: "=",
					result: "=",
					main: "=",
					pair: "@",
					pairModes: "="
				},
				templateUrl: '/static/templates/directive/result-efi.html',
				controller: ['$scope', '$element', 'aerosApi', 'viewEfiImageModal', 'TestResultsService',
                    function ($scope, $element, aerosApi, viewEfiImageModal, TestResultsService) {
					var fiberGroup = $scope.fiberGroup;
					var result = $scope.result;
					var isMain = $scope.main;
					var pair = $scope.pair;
					var pairModes = $scope.pairModes;

					if (result.inspectionMeasurements) {
						if (isMain && result.inspectionMeasurements.Main) {
							if (pair && result.inspectionMeasurements.Main[pair]) {
								$scope.efi = result.inspectionMeasurements.Main[pair];
							} else {
								$scope.efi = result.inspectionMeasurements.Main;
                                $scope.standardMain = result.inspectionMeasurements.Main.standard ;
							}
						} else if (!isMain && result.inspectionMeasurements.Remote) {
							if (pair && result.inspectionMeasurements.Remote[pair]) {
								$scope.efi = result.inspectionMeasurements.Remote[pair];
							} else {
								$scope.efi = result.inspectionMeasurements.Remote;
                                $scope.standardRemote = result.inspectionMeasurements.Remote.standard ;
							}
						}

						if ($scope.efi) {
							$element.addClass($scope.efi.status);
						}

						var required = isMain ? fiberGroup.requiresMainEfi : fiberGroup.requiresRemoteEfi;
						$scope.shouldHave = required && !$scope.efi;

                        var testResults = [] ; // getProjectInspectionStandards expects an array of tests
                        testResults.push($scope.result) ;

						// Get inspection standard from the current project fibergroup inspection test setup
                        if ($scope.$root.currentProject.fiberGroups) {
                            var thisFG = $scope.$root.currentProject.fiberGroups.filter(function (fg) {
                                return (fg.id == fiberGroup.id);
                            });
                            if (thisFG.length > 0) {
                                var inspection = thisFG[0].testSetups.filter(function (test) {
                                    return (test.type == "INSPECTION") ;
                                }) ;
                                if (inspection) {
                                    $scope.standard = inspection[0].rule ;
                                }
                            }
                        }

						$scope.showEFIImage = function() {
							aerosApi.loadImageMetadata($scope.efi.mainImageId).then(function (rtn) {
								viewEfiImageModal.open(
										$scope.efi, 
										fiberGroup, 
										result.fiberId, isMain ? fiberGroup.endNameMain : fiberGroup.endNameRemote,
										pair ? pairModes[fiberGroup.pairMode][pair.toLowerCase()] : false,
										(rtn.data && rtn.data.metaData) ? rtn.data.metaData.requirements : false,
                                        $scope.standard
								);								
							}, function (err) {
								viewEfiImageModal.open(
										$scope.efi, 
										fiberGroup, 
										result.fiberId, isMain ? fiberGroup.endNameMain : fiberGroup.endNameRemote,
										pair ? pairModes[fiberGroup.pairMode][pair.toLowerCase()] : false,
                                        $scope.standard
								);								
							});
						};
					}
				}
			]};
		}]);
})();
